import set from 'lodash/set';
import { Color, Rgba } from 'types/color.type';
import merge from 'lodash/merge';
import WebFont from 'webfontloader';
import axios from 'axios';
import cloneDeep from 'helpers/cloneDeep';
import { ColorHelpers } from 'helpers/colors.helpers';
import User from 'components/data/User';
import { NumberHelpers } from 'helpers/number.helpers';
import {
    FigmaPNGBackupImageLayer,
    FigmaFrameLayer,
    FigmaGroupLayer,
    FigmaInstanceLayer,
    FigmaLayer,
    FigmaSVGBackupImageLayer,
    FigmaRectangleLayer,
    FigmaSectionLayer,
    FigmaTextLayer
} from '../types/figma.types';
import Layer from '../types/layer.type';
import {
    AllProperties,
    BlendModeOptions,
    Border,
    BorderRadiusUnitOptions,
    BorderStyleOptions,
    ContainerProperties,
    FontSizeUnitOptions,
    ImageProperties,
    Media,
    MediaPositionOptions,
    MediaRepeatOptions,
    MediaSizeOptions,
    OverflowOptions,
    Properties,
    ResizeOptions,
    Shadow,
    ShadowStyleOptions,
    ShapeProperties,
    SizeUnitOptions,
    TextAlignHorizontalOptions,
    TextAlignVerticalOptions,
    TextDecorationOptions,
    TextDecorationStyleOptions,
    TextProperties,
    TextShadow,
    TextShadowStyleOptions,
    TextStyling,
    TextTransformOptions
} from '../types/layerProperties.type';
import Template, { UnitOptions, View } from '../types/template.type';
import Format from '../types/format.type';
import { generateKey } from '../utils/generateKey';
import { LayerPropertiesHelpers } from './layer-properties.helpers';
import { defaultLayerProperties } from '../config/layer-properties/default-layer-properties';
import { defaultColor } from '../config/layer-properties/default-color';
import { roundToNearestDecimal } from '../utils/roundNumbers';
import Src from '../types/src.type';
import { defaultProperties } from '../config/layer-properties/default-properties';
import { FontHelpers } from './font.helpers';
import { GoogleFonts } from '../models/google-fonts';
import TemplateDesignerStore, { MultiModel } from '../data/template-designer-store';
import { getTemplateData } from './data.helpers';
import FormatHelpers from './format.helpers';
import { PERCENTAGE_ROUNDING_PRECISION } from '../constants';

type FigmaBorderRadius = {
    topLeftRadius: number;
    topRightRadius: number;
    bottomRightRadius: number;
    bottomLeftRadius: number;
};

type FigmaStrokeWeight = {
    strokeTopWeight: number;
    strokeRightWeight: number;
    strokeBottomWeight: number;
    strokeLeftWeight: number;
};

interface ConvertFigmaLayersOptions {
    isChild?: boolean;
    parentIsRotated?: boolean;
    parentIsGroup?: boolean;
    groupOffset?: { x: number; y: number; rotationXDiff: number; rotationYDiff: number };
    seedLayers?: Layer[];
    titleRoute?: string;
    first?: boolean;
    parentSize?: { width: number; height: number };
}

class FigmaHelpers {
    /**
     * Convert Figma layers to TD layers.
     * @param figmaLayers - Figma layers.
     * @param layerProperties - Layer properties.
     * @returns TD layers and updated layer properties.
     */
    static handleFigmaLayers = async (figmaLayers: FigmaLayer[]): Promise<void> => {
        const frameType = getTemplateData<View['frameType']>('view.frameType');
        const layers = getTemplateData<Template['layers']>('layers');
        const formats = getTemplateData<Template['formats']>('formats');
        const frameLayers = layers[frameType];
        const changes: MultiModel = [];

        // When the user copies multiple frames from Figma we want to create a new format for each frame.
        // This only happens when there are only frames in the base layers copied and if the all have the same structure.
        if (
            formats.length === 0 &&
            figmaLayers.length &&
            figmaLayers.every((layer) => layer.type === 'FRAME') &&
            (figmaLayers.length === 1 || FigmaHelpers.isSameStructure(figmaLayers))
        ) {
            const newFormats: Template['formats'] = [];
            // Seedlayers is the layers object created by the first format. We use these layer keys to create the other formats.
            let seedLayers: Layer[] = [];

            for (const [index, figmaLayer] of figmaLayers.entries()) {
                /**
                 * Get the already existing format that corresponds to the size of the frame or create a new format.
                 */
                const relevantTDFormats = FormatHelpers.getRelevantTemplateDesignerFormats();
                const templateDesignerFormat = relevantTDFormats.find((format) => format.width === figmaLayer.width && format.height === figmaLayer.height);

                const format: Format = templateDesignerFormat ?? {
                    key: generateKey(figmaLayer.name),
                    title: figmaLayer.name,
                    width: figmaLayer.width,
                    height: figmaLayer.height,
                    type: 'custom',
                    sets: [],
                    category: 'custom'
                };

                newFormats.push(format);

                const layerProperties = getTemplateData<Template['layerProperties']['general']['main']>(`layerProperties.general.${frameType}`);
                const { layers, layerProperties: newLayerProperties } = await FigmaHelpers.convertFigmaLayers([figmaLayer], layerProperties, {
                    seedLayers,
                    // Title route is used to find the correct seedLayer for the layer. This is all based on title.
                    // We start with the title of the first seedLayer and looping through each layer we add the title of the current layer to it.
                    // Then give the titleRoute to the next layer. e.g. "Frame 1.Text Container.Headline 1"
                    // This way we can find the correct seedLayer (based on titles) for the next layer.
                    titleRoute: seedLayers[0]?.title,
                    first: true,
                    parentSize: { width: format.width, height: format.height }
                });

                // The first layer is also added to general.
                // And we take the layers object produced by it as seed for the next frames.
                if (index === 0) {
                    changes.push([`layerProperties.general.${frameType}`, newLayerProperties]);
                    const organizedLayers = FigmaHelpers.reorganizeLayers(layers);
                    seedLayers = layers;
                    frameLayers.unshift(...organizedLayers);
                }

                if (index !== 0) {
                    changes.push([`layerProperties.${format.key}.${frameType}`, newLayerProperties]);
                }
            }

            if (newFormats.length) FormatHelpers.addFormats(newFormats);

            TemplateDesignerStore.save([[`layers.${frameType}`, frameLayers], ['state.isUploading', false], ...changes]);
        } else if (formats.length !== 0) {
            const layerProperties = getTemplateData<Template['layerProperties']['general']['main']>(`layerProperties.general.${frameType}`);
            const { layers: newLayers, layerProperties: newLayerProperties } = await FigmaHelpers.convertFigmaLayers(figmaLayers, layerProperties);

            // Because the main recursive function returns a nested layers object we need to reorganize the layers to remove the nesting on rotated layers
            const organizedLayers = FigmaHelpers.reorganizeLayers(newLayers);
            frameLayers.unshift(...organizedLayers);

            changes.push([`layerProperties.general.${frameType}`, newLayerProperties]);

            TemplateDesignerStore.save([[`layers.${frameType}`, frameLayers], ['state.isUploading', false], ...changes]);
        }
    };

    /**
     * Compares the structure of the Figma layers and returns true if they are the same
     * @param figmaLayers The Figma layers to compare the structure of
     * @returns If the structure of the layers is the same
     */
    private static isSameStructure = (figmaLayers: FigmaLayer[]): boolean => {
        if (figmaLayers.length < 2) return true;

        const serialize = (node: FigmaLayer, isRoot = false): string => {
            const sortedChildren = node.children?.slice().sort((a, b) => a.name.localeCompare(b.name)) || [];
            return isRoot
                ? sortedChildren.map((child) => serialize(child)).join(',')
                : `${node.name}(${sortedChildren.map((child) => serialize(child)).join(',')})`;
        };

        const reference = serialize(figmaLayers[0], true);

        return figmaLayers.every((obj) => serialize(obj, true) === reference);
    };

    /**
     * Convert Figma layers to TD layers recursively.
     * @param figmaLayers - Figma layers.
     * @param layerProperties - Layer properties.
     * @returns TD layers and updated layer properties.
     */
    private static convertFigmaLayers = async (
        figmaLayers: FigmaLayer[],
        layerProperties: Template['layerProperties']['general']['main'],
        options: ConvertFigmaLayersOptions = {}
    ): Promise<{ layers: Layer[]; layerProperties: Template['layerProperties']['general']['main'] }> => {
        const { isChild, parentIsRotated, parentIsGroup, groupOffset, seedLayers, titleRoute, first, parentSize } = options;

        // We need predictable and unique names for the layers.
        // So we check if a layer already exists if it does we add an index to it.
        // This way we know for sure that we do not end up with unnpredictable behaviour when the seedLayers and titleRoute are used.
        const nameObject = {};
        figmaLayers.forEach((figmaLayer) => {
            if (nameObject[figmaLayer.name]) {
                nameObject[figmaLayer.name]++;
            } else {
                nameObject[figmaLayer.name] = 1;
            }
            if (nameObject[figmaLayer.name] > 1) {
                figmaLayer.name = `${figmaLayer.name} ${nameObject[figmaLayer.name] - 1}`;
            }
        });

        const layers = await Promise.all(
            figmaLayers.map(async (figmaLayer) => {
                // Check if the layer has a key that is in the seedLayers. If it does we use that key for the new layer.
                const seedKey: string | null = (() => {
                    if (!seedLayers) return null;
                    const routeToFollow = first ? titleRoute : titleRoute + `.${figmaLayer.name}`;
                    return FigmaHelpers.findObjectByTitlePath(seedLayers, routeToFollow)?.key || null;
                })();

                const newLayer: Layer & {
                    rotated?: boolean;
                } = {
                    key: seedKey || generateKey('shape'),
                    type: 'shape',
                    title: figmaLayer.name,
                    children: []
                };

                const partialLayerProperties: Partial<AllProperties> = {};

                // We get rotation from transform because for groups the figmaLayer.rotation prop is not always accurate.
                const rotation = FigmaHelpers.getRotationFromTransform(figmaLayer.absoluteTransform) || ('rotation' in figmaLayer && -figmaLayer.rotation) || 0;

                if (rotation !== 0) {
                    partialLayerProperties.rotation = rotation;
                }

                let newLayerLayerProperties: AllProperties;

                // We convert all the layer types with their own convert function.
                switch (figmaLayer.type) {
                    case 'RECTANGLE': {
                        newLayerLayerProperties = await FigmaHelpers.convertRectangleLayer(figmaLayer, newLayer, partialLayerProperties, parentSize);
                        break;
                    }
                    case 'TEXT': {
                        newLayerLayerProperties = await FigmaHelpers.convertTextLayer(figmaLayer, newLayer, partialLayerProperties, parentSize);
                        break;
                    }
                    case 'POLYGON':
                    case 'STAR':
                    case 'ELLIPSE':
                    case 'VECTOR':
                    case 'LINE':
                    case 'BOOLEAN_OPERATION':
                    case 'EMBED':
                    case 'LINK_UNFURL':
                    case 'SHAPE_WITH_TEXT':
                    case 'SLICE':
                    case 'STAMP':
                    case 'STICKY':
                    case 'TABLE':
                    case 'WIDGET':
                    case 'CONNECTOR':
                    case 'CODE_BLOCK': {
                        newLayerLayerProperties = await FigmaHelpers.convertBackupImageLayer(figmaLayer, newLayer, partialLayerProperties, parentSize);
                        break;
                    }
                    case 'GROUP':
                    case 'COMPONENT':
                    case 'COMPONENT_SET': {
                        newLayerLayerProperties = await FigmaHelpers.convertGroupLayer(figmaLayer, newLayer, partialLayerProperties, parentSize);
                        break;
                    }
                    case 'INSTANCE': {
                        newLayerLayerProperties = await FigmaHelpers.convertInstanceLayer(figmaLayer, newLayer, partialLayerProperties, parentSize);
                        break;
                    }
                    case 'SECTION': {
                        newLayerLayerProperties = await FigmaHelpers.convertSectionLayer(figmaLayer, newLayer, partialLayerProperties, parentSize);
                        break;
                    }
                    case 'FRAME': {
                        newLayerLayerProperties = await FigmaHelpers.convertFrameLayer(figmaLayer, newLayer, partialLayerProperties, parentSize);
                        break;
                    }
                    default: {
                        newLayerLayerProperties = { ...LayerPropertiesHelpers.getDefaultProperties('shape'), ...partialLayerProperties } as ShapeProperties;
                    }
                }

                // If the layer has a seedKey we use that key for the new layer.
                // We overwrite the key here because in some cases the convert functions also generate a new key.
                if (seedKey) {
                    newLayer.key = seedKey;
                }

                const isRotated = parentIsRotated || (!!newLayerLayerProperties.rotation && newLayerLayerProperties.rotation !== 0);

                // If there are children we need to convert those as well.
                if (figmaLayer.children.length) {
                    newLayer.children = await FigmaHelpers.generateChildren(
                        figmaLayer,
                        newLayerLayerProperties,
                        layerProperties,
                        newLayer,
                        isRotated,
                        groupOffset,
                        titleRoute,
                        first,
                        seedLayers
                    );
                }

                if (isChild) {
                    const { x, y } = FigmaHelpers.calculatePosition(
                        figmaLayer,
                        newLayerLayerProperties,
                        parentIsRotated,
                        parentIsGroup,
                        groupOffset,
                        isRotated,
                        parentSize
                    );
                    newLayerLayerProperties.x = x;
                    newLayerLayerProperties.y = y;
                }

                const newLayerProperties = cloneDeep(defaultLayerProperties);
                newLayerProperties.properties = newLayerLayerProperties;
                set(layerProperties, `${newLayer.key}`, newLayerProperties);

                return newLayer;
            })
        );

        return { layers, layerProperties };
    };

    /**
     * Generate the children of a Figma layer
     * @param figmaLayer The Figma layer
     * @param newLayerLayerProperties The layer properties of the new layer
     * @param layerProperties The layer properties of the template
     * @param newLayer The new layer
     * @param isRotated If the layer is rotated
     * @param groupOffset The group offset
     * @param titleRoute The title route (this is used to find the correct seed layer for the new layer)
     * @param first If this is the firs tlayer
     * @param seedLayers The seed layers to base the layer keys on
     * @returns The child layers
     */
    private static generateChildren = async (
        figmaLayer: FigmaLayer,
        newLayerLayerProperties: AllProperties,
        layerProperties: Template['layerProperties']['general']['main'],
        newLayer: Layer & {
            rotated?: boolean;
        },
        isRotated: boolean,
        groupOffset: ConvertFigmaLayersOptions['groupOffset'],
        titleRoute: ConvertFigmaLayersOptions['titleRoute'],
        first: ConvertFigmaLayersOptions['first'],
        seedLayers: ConvertFigmaLayersOptions['seedLayers']
    ): Promise<Layer[]> => {
        // We set the rotated flag to later on remove children and nesting from this layer. Because the coordinate system in Figma workes a bit different.
        // In Figma the x-axis always stays horizontal. In TD the x-axis rotates along with the rotation of the parent.
        if (isRotated) {
            newLayer.rotated = true;
        }

        const newGroupOffset = (() => {
            if (groupOffset && isRotated) return groupOffset;

            const { rotationXDiff, rotationYDiff } = FigmaHelpers.getRotationXYDiff(figmaLayer.width, figmaLayer.height, newLayerLayerProperties.rotation || 0);
            return { ...FigmaHelpers.getAbsolutePosition(figmaLayer), rotationXDiff, rotationYDiff };
        })();

        const newTitleRoute = (() => {
            if (first) return titleRoute;
            if (titleRoute) return `${titleRoute}.${newLayer.title}`;
            return newLayer.title;
        })();

        return (
            await FigmaHelpers.convertFigmaLayers(figmaLayer.children.reverse(), layerProperties, {
                isChild: true,
                parentIsRotated: isRotated,
                parentIsGroup: figmaLayer.type === 'GROUP' || figmaLayer.type === 'FRAME',
                groupOffset: newGroupOffset,
                seedLayers,
                titleRoute: newTitleRoute,
                parentSize: { width: figmaLayer.width, height: figmaLayer.height }
            })
        ).layers;
    };

    /**
     * Get the absolute position of a Figma layer.
     * @param figmaLayer - Figma layer.
     * @param newLayerLayerProperties - Layer properties.
     * @param parentIsRotated - If the parent is rotated.
     * @param parentIsGroup - If the parent is a group.
     * @param groupOffset - Group offset.
     * @param isRotated - If the layer is rotated.
     * @returns The position.
     */
    private static calculatePosition = (
        figmaLayer: FigmaLayer,
        newLayerLayerProperties: AllProperties,
        parentIsRotated: ConvertFigmaLayersOptions['parentIsRotated'],
        parentIsGroup: ConvertFigmaLayersOptions['parentIsGroup'],
        groupOffset: ConvertFigmaLayersOptions['groupOffset'],
        isRotated: boolean,
        parentSize: ConvertFigmaLayersOptions['parentSize']
    ): { x: Properties['x']; y: Properties['y'] } => {
        // If the parent is rotated we are not nesting the child layer in the parent.
        // So we need the absolute position of the layer and get the actual position by subtracting the groupOffset.
        const shouldUseAbsolutePosition = (parentIsRotated || parentIsGroup) && groupOffset;
        const layerOffset = shouldUseAbsolutePosition ? FigmaHelpers.getAbsolutePosition(figmaLayer) : FigmaHelpers.getRelativePosition(figmaLayer);

        let x = layerOffset.x;
        let y = layerOffset.y;

        if (shouldUseAbsolutePosition) {
            x = x - groupOffset.x;
            y = y - groupOffset.y;

            if (parentIsRotated) {
                x = x + groupOffset.rotationXDiff;
                y = y + groupOffset.rotationYDiff;
            }
        }

        const layerFlipped = FigmaHelpers.getLayerFlip(figmaLayer);
        // If a layer is flipped we ignore al rotations and make an image out of it we then only need to correct the position
        // TODO in case the layer is rotated and flipped this code is not good enough
        if (layerFlipped.flippedX || layerFlipped.flippedY) {
            newLayerLayerProperties.rotation = 0;
            if (layerFlipped.flippedX) {
                x = x - figmaLayer.width;
            }
            if (layerFlipped.flippedY) {
                y = y - figmaLayer.height;
            }
        } else if (isRotated) {
            const { rotationXDiff, rotationYDiff } = FigmaHelpers.getRotationXYDiff(figmaLayer.width, figmaLayer.height, newLayerLayerProperties.rotation || 0);
            x = x - rotationXDiff;
            y = y - rotationYDiff;
        }

        let unit = SizeUnitOptions.Px;
        x = Math.round(x + Number(newLayerLayerProperties.x.value));
        y = Math.round(y + Number(newLayerLayerProperties.y.value));

        if (parentSize) {
            x = roundToNearestDecimal(NumberHelpers.calculatePercentage(parentSize.width, x), PERCENTAGE_ROUNDING_PRECISION);
            y = roundToNearestDecimal(NumberHelpers.calculatePercentage(parentSize.height, y), PERCENTAGE_ROUNDING_PRECISION);
            unit = SizeUnitOptions['%'];
        }

        return {
            x: {
                value: x,
                unit: unit
            },
            y: {
                value: y,
                unit: unit
            }
        };
    };

    /**
     * Find an object in a nested structure by traversing a dot-separated path of titles
     * @param {Object[]} structure - Array of objects with title and children properties
     * @param {string} titlePath - Dot-separated path of titles (e.g., "Parent1.Child1")
     * @returns {Object|null} - The found object or null if not found
     */
    private static findObjectByTitlePath = (structure, titlePath) => {
        if (!structure || !titlePath) {
            return null;
        }

        // Split the path into individual title components
        const titles = titlePath.split('.');
        let currentTitle = titles[0];

        // Find the first level object
        let currentObject = structure.find((obj) => obj.title === currentTitle);
        if (!currentObject) {
            return null;
        }

        // If we only needed to find the first level, return it
        if (titles.length === 1) {
            return currentObject;
        }

        // Otherwise, traverse through the remaining path
        for (let i = 1; i < titles.length; i++) {
            currentTitle = titles[i];

            // If there are no children or children is not an array, we can't go deeper
            if (!currentObject.children || !Array.isArray(currentObject.children)) {
                return null;
            }

            // Find the next object in the children array
            currentObject = currentObject.children.find((child) => child.title === currentTitle);

            // If we couldn't find a match at this level, return null
            if (!currentObject) {
                return null;
            }
        }

        return currentObject;
    };

    /**
     * Removes children and nesting from layers that have the rotated flag.
     * We need to remove nesting form layers that have a rotation.
     * We add these child layers to the first parent that is not rotated.
     * Because the coordinate system works different in Figma for rotated layers.
     * @param items The items to reorganize
     * @returns The newly organized layers
     */
    private static reorganizeLayers(
        items: (Layer & {
            rotated?: boolean;
        })[]
    ): Layer[] {
        // Result array
        const result: Layer[] = [];

        // Helper function to process items recursively
        function processItems(
            currentItems: (Layer & {
                rotated?: boolean;
            })[],
            currentParent:
                | (Layer & {
                      rotated?: boolean;
                  })
                | null
        ) {
            for (const item of currentItems) {
                // Check if this item is rotated
                if (item.rotated === true) {
                    // If rotated, process its children directly under the current parent
                    if (item.children && item.children.length > 0) {
                        processItems(item.children, currentParent);
                    }
                    item.children = [];
                    if (currentParent) {
                        if (!currentParent.children) {
                            currentParent.children = [];
                        }
                        currentParent.children.push(item);
                    } else {
                        result.push(item);
                    }
                    // Skip adding this item to the result
                } else {
                    // Create a copy of the item without its children
                    const newItem: Layer = { ...item, children: [] };

                    // Add to appropriate parent
                    if (currentParent) {
                        if (!currentParent.children) {
                            currentParent.children = [];
                        }
                        currentParent.children.push(newItem);
                    } else {
                        result.push(newItem);
                    }

                    // Process children if they exist
                    if (item.children && item.children.length > 0) {
                        processItems(item.children, newItem);
                    }
                }

                delete item.rotated;
            }
        }

        // Start processing from the top level with no parent
        processItems(items, null);

        return result;
    }

    /**
     * In Figma the layer is positioned from the top left corner of the layer. Even if the layer is rotated.
     * In TD the layer is positioned from the top left corner of the unrotated layer.
     * So we need to calculate that difference in position to compensate for the rotation.
     * @param width Width of the layer
     * @param height Height of the layer
     * @param rotation Rotation of the layer
     * @returns The offset from the original position to the rotated position
     */
    private static getRotationXYDiff(width, height, rotation): { rotationXDiff: number; rotationYDiff: number } {
        if (rotation === 0) return { rotationXDiff: 0, rotationYDiff: 0 };
        const radians = (rotation * Math.PI) / 180;

        // Calculate the distance from center to corner
        const centerToCornerX = width / 2;
        const centerToCornerY = height / 2;

        // Calculate the new position of the corner after rotation
        const rotatedX = -centerToCornerX * Math.cos(radians) + centerToCornerY * Math.sin(radians);
        const rotatedY = -centerToCornerX * Math.sin(radians) - centerToCornerY * Math.cos(radians);

        // Calculate the offset from original to rotated position
        const rotationXDiff = rotatedX + centerToCornerX;
        const rotationYDiff = rotatedY + centerToCornerY;

        return { rotationXDiff, rotationYDiff };
    }

    /**
     * Convert a figma layer to a shape layer.
     * @param figmaLayer The figma layer to convert
     * @param partialLayer The partial layer properties to add to the converted layer
     * @returns The converted shape layer
     */
    private static convertRectangleLayer = async (
        figmaLayer: FigmaRectangleLayer,
        newLayer: Layer,
        partialLayer: Partial<AllProperties>,
        parentSize: ConvertFigmaLayersOptions['parentSize']
    ): Promise<ShapeProperties> => {
        const visibility = figmaLayer.visible;
        if (figmaLayer.image) {
            const rectangleSpecificProperties = await FigmaHelpers.getBackupImage(figmaLayer);

            newLayer.type = 'image';
            newLayer.key = generateKey('image');

            const { width: widthWithShadow, height: heightWithShadow, topExtension, leftExtension } = FigmaHelpers.calculateBoundsWithShadow(figmaLayer);

            const width = FigmaHelpers.convertSize(widthWithShadow, parentSize?.width);
            const height = FigmaHelpers.convertSize(heightWithShadow, parentSize?.height);

            rectangleSpecificProperties.x = {
                value: Math.round(-leftExtension),
                unit: SizeUnitOptions.Px
            };
            rectangleSpecificProperties.y = {
                value: Math.round(-topExtension),
                unit: SizeUnitOptions.Px
            };
            rectangleSpecificProperties.width = width;
            rectangleSpecificProperties.height = height;
            rectangleSpecificProperties.display = visibility;

            return merge(rectangleSpecificProperties, partialLayer);
        }

        const rectangleSpecificProperties = LayerPropertiesHelpers.getDefaultProperties('shape') as ShapeProperties;
        newLayer.type = 'shape';
        newLayer.key = generateKey('shape');

        // Box settings
        const width = FigmaHelpers.convertSize(figmaLayer.width, parentSize?.width);
        const height = FigmaHelpers.convertSize(figmaLayer.height, parentSize?.height);
        const maxWidth = FigmaHelpers.convertSize(figmaLayer.maxWidth, parentSize?.width);
        const maxHeight = FigmaHelpers.convertSize(figmaLayer.maxHeight, parentSize?.height);

        // Appearance
        const blendMode = FigmaHelpers.convertBlendMode(figmaLayer.blendMode);

        // Background
        const fill = figmaLayer.fills?.[0];
        const backgroundColor = fill && FigmaHelpers.convertColor(fill.color, fill.opacity);

        // Border
        const borderRadius = FigmaHelpers.convertBorderRadius({
            topLeftRadius: figmaLayer.topLeftRadius,
            topRightRadius: figmaLayer.topRightRadius,
            bottomRightRadius: figmaLayer.bottomRightRadius,
            bottomLeftRadius: figmaLayer.bottomLeftRadius
        });
        const border =
            figmaLayer.strokes?.[0] &&
            FigmaHelpers.convertBorder(figmaLayer.strokes[0], {
                strokeTopWeight: figmaLayer.strokeTopWeight,
                strokeRightWeight: figmaLayer.strokeRightWeight,
                strokeBottomWeight: figmaLayer.strokeBottomWeight,
                strokeLeftWeight: figmaLayer.strokeLeftWeight
            });

        // Shadow
        const shadow = figmaLayer.effects?.[0] && FigmaHelpers.convertShadow(figmaLayer.effects[0]);

        rectangleSpecificProperties.width = width;
        rectangleSpecificProperties.height = height;
        rectangleSpecificProperties.maxWidth = maxWidth;
        rectangleSpecificProperties.maxHeight = maxHeight;
        rectangleSpecificProperties.blendMode = blendMode;
        rectangleSpecificProperties.display = visibility;
        if (backgroundColor && ColorHelpers.isColor(backgroundColor)) {
            rectangleSpecificProperties.background = backgroundColor;
        }
        rectangleSpecificProperties.borderRadius = borderRadius;
        rectangleSpecificProperties.border = border;
        rectangleSpecificProperties.shadow = shadow;

        return merge({}, rectangleSpecificProperties, partialLayer);
    };

    /**
     * Convert a figma text layer to a text or image layer.
     * @param figmaLayer The figma layer to convert
     * @param partialLayer The partial layer properties to add to the converted layer
     * @returns The converted text or image layer
     */
    private static convertTextLayer = async (
        figmaLayer: FigmaTextLayer,
        newLayer: Layer,
        partialLayer: Partial<AllProperties>,
        parentSize: ConvertFigmaLayersOptions['parentSize']
    ): Promise<TextProperties | ImageProperties> => {
        const width = FigmaHelpers.convertWidth(figmaLayer.textAutoResize, figmaLayer.width, !!figmaLayer.image, parentSize?.width);
        const height = FigmaHelpers.convertHeight(figmaLayer.textAutoResize, figmaLayer.height, !!figmaLayer.image, parentSize?.height);
        const visibility = figmaLayer.visible;

        if (figmaLayer.image) {
            const textSpecificProperties = await FigmaHelpers.getBackupImage(figmaLayer);
            newLayer.type = 'image';
            newLayer.key = generateKey('image');

            textSpecificProperties.width = width;
            textSpecificProperties.height = height;
            textSpecificProperties.display = visibility;
            textSpecificProperties.media.position = (() => {
                switch (figmaLayer.textAlignHorizontal) {
                    case 'LEFT':
                        return MediaPositionOptions.LeftCenter;
                    case 'CENTER':
                        return MediaPositionOptions.CenterCenter;
                    case 'RIGHT':
                        return MediaPositionOptions.RightCenter;
                }
                return MediaPositionOptions.LeftCenter;
            })();

            return merge({}, textSpecificProperties, partialLayer);
        }

        const textSpecificProperties = LayerPropertiesHelpers.getDefaultProperties('text') as TextProperties;
        newLayer.type = 'text';
        newLayer.key = generateKey('text');

        // Text
        const text = FigmaHelpers.isFigmaMixed(figmaLayer.textDecoration) || !figmaLayer.textDecoration ? figmaLayer.styledCharacters : figmaLayer.characters;
        const textTransform = FigmaHelpers.convertTextTransform(figmaLayer.textCase);
        const fontSize = typeof figmaLayer.fontSize !== 'symbol' ? { value: Math.round(Number(figmaLayer.fontSize)), unit: FontSizeUnitOptions.Px } : undefined;
        const fill = figmaLayer.fills?.[0];
        const fillColor = fill && FigmaHelpers.convertColor(fill.color, fill.opacity);
        const fontName = FigmaHelpers.convertFontName(figmaLayer.fontName, figmaLayer.name);
        const fontSource = FigmaHelpers.convertFontSource(fontName);
        const fontVariant = FigmaHelpers.convertFontVariant(figmaLayer.fontWeight, fontSource);
        const lineHeight = FigmaHelpers.convertLineHeight(figmaLayer.lineHeight, figmaLayer.fontSize);
        const letterSpacing = FigmaHelpers.convertLetterSpacing(figmaLayer.letterSpacing, figmaLayer.fontSize);
        const textShadow = figmaLayer.effects?.[0] && FigmaHelpers.convertTextShadow(figmaLayer.effects[0]);
        const textAlignHorizontal = FigmaHelpers.convertTextHorizontalAlignment(figmaLayer.textAlignHorizontal);
        const textAlignVertical = FigmaHelpers.convertTextVerticalAlignment(figmaLayer.textAlignVertical);
        const textDecoration = !FigmaHelpers.isFigmaMixed(figmaLayer.textDecoration) ? FigmaHelpers.convertTextDecoration(figmaLayer.textDecoration) : null;
        const textDecorationStyle = !FigmaHelpers.isFigmaMixed(figmaLayer.textDecorationStyle)
            ? FigmaHelpers.convertTextDecorationStyle(figmaLayer.textDecorationStyle)
            : null;

        // Box settings
        const opacity = figmaLayer.opacity;

        textSpecificProperties.text = text;
        textSpecificProperties.textStyling.normal.textTransform = textTransform;
        if (fontSize) textSpecificProperties.textStyling.normal.fontSize = fontSize;
        textSpecificProperties.textStyling.normal.color = fillColor;
        textSpecificProperties.textStyling.normal.fontFamily = fontName;
        textSpecificProperties.textStyling.normal.fontName = fontName;
        textSpecificProperties.textStyling.normal.fontSource = fontSource;
        textSpecificProperties.textStyling.normal.fontVariant = fontVariant;
        if (lineHeight) textSpecificProperties.textStyling.normal.lineHeight = roundToNearestDecimal(lineHeight, PERCENTAGE_ROUNDING_PRECISION);
        textSpecificProperties.textStyling.normal.letterSpacing = letterSpacing;
        textSpecificProperties.textStyling.normal.textShadow = textShadow;
        textSpecificProperties.textStyling.normal.textAlignHorizontal = textAlignHorizontal;
        textSpecificProperties.textStyling.normal.textAlignVertical = textAlignVertical;
        if (textDecoration) textSpecificProperties.textStyling.normal.textDecoration = textDecoration;
        if (textDecorationStyle) textSpecificProperties.textStyling.normal.textDecorationStyle = textDecorationStyle;
        textSpecificProperties.opacity = opacity;
        textSpecificProperties.width = width;
        textSpecificProperties.height = height;
        textSpecificProperties.display = visibility;

        return merge({}, textSpecificProperties, partialLayer);
    };

    /**
     * Convert a figma polygon layer to a image layer.
     * @param figmaLayer The figma layer to convert
     * @param newLayer The converted layer
     * @param partialLayer The partial layer properties to add to the converted layer
     * @returns The converted image layer
     */
    private static convertBackupImageLayer = async (
        figmaLayer: FigmaSVGBackupImageLayer | FigmaPNGBackupImageLayer,
        newLayer: Layer,
        partialLayer: Partial<AllProperties>,
        parentSize: ConvertFigmaLayersOptions['parentSize']
    ): Promise<ImageProperties> => {
        const width = FigmaHelpers.convertSize(figmaLayer.width, parentSize?.width);
        const height = FigmaHelpers.convertSize(figmaLayer.height, parentSize?.height);
        const visibility = figmaLayer.visible;

        const polygonSpecificProperties = await FigmaHelpers.getBackupImage(figmaLayer);
        newLayer.type = 'image';
        newLayer.key = generateKey('image');

        polygonSpecificProperties.width = width;
        polygonSpecificProperties.height = height;
        polygonSpecificProperties.display = visibility;

        return merge({}, polygonSpecificProperties, partialLayer);
    };

    /**
     * Convert a figma group layer to a container layer.
     * @param figmaLayer The figma layer to convert
     * @param newLayer The converted layer
     * @param partialLayer The partial layer properties to add to the converted layer
     * @returns The converted image layer
     */
    private static convertGroupLayer = (
        figmaLayer: FigmaGroupLayer,
        newLayer: Layer,
        partialLayer: Partial<AllProperties>,
        parentSize: ConvertFigmaLayersOptions['parentSize']
    ): Promise<ContainerProperties> => {
        const width = FigmaHelpers.convertSize(figmaLayer.width, parentSize?.width);
        const height = FigmaHelpers.convertSize(figmaLayer.height, parentSize?.height);
        const visibility = figmaLayer.visible;

        const groupSpecificProperties = LayerPropertiesHelpers.getDefaultProperties('container') as ContainerProperties;
        newLayer.type = 'container';
        newLayer.key = generateKey('container');

        groupSpecificProperties.width = width;
        groupSpecificProperties.height = height;
        groupSpecificProperties.display = visibility;

        return merge({}, groupSpecificProperties, partialLayer);
    };

    /**
     * Convert a figma isntance layer to a container layer.
     * @param figmaLayer The figma layer to convert
     * @param newLayer The converted layer
     * @param partialLayer The partial layer properties to add to the converted layer
     * @returns The converted image layer
     */
    private static convertInstanceLayer = (
        figmaLayer: FigmaInstanceLayer,
        newLayer: Layer,
        partialLayer: Partial<AllProperties>,
        parentSize: ConvertFigmaLayersOptions['parentSize']
    ): Promise<ContainerProperties> => {
        const width = FigmaHelpers.convertSize(figmaLayer.width, parentSize?.width);
        const height = FigmaHelpers.convertSize(figmaLayer.height, parentSize?.height);
        const visibility = figmaLayer.visible;

        const instanceSpecificProperties = LayerPropertiesHelpers.getDefaultProperties('container') as ContainerProperties;
        newLayer.type = 'container';
        newLayer.key = generateKey('container');
        // Border
        const borderRadius = FigmaHelpers.convertBorderRadius({
            topLeftRadius: figmaLayer.topLeftRadius,
            topRightRadius: figmaLayer.topRightRadius,
            bottomRightRadius: figmaLayer.bottomRightRadius,
            bottomLeftRadius: figmaLayer.bottomLeftRadius
        });
        const border =
            figmaLayer.strokes?.[0] &&
            FigmaHelpers.convertBorder(figmaLayer.strokes[0], {
                strokeTopWeight: figmaLayer.strokeTopWeight,
                strokeRightWeight: figmaLayer.strokeRightWeight,
                strokeBottomWeight: figmaLayer.strokeBottomWeight,
                strokeLeftWeight: figmaLayer.strokeLeftWeight
            });

        instanceSpecificProperties.width = width;
        instanceSpecificProperties.height = height;
        instanceSpecificProperties.display = visibility;
        instanceSpecificProperties.borderRadius = borderRadius;
        instanceSpecificProperties.border = border;

        return merge({}, instanceSpecificProperties, partialLayer);
    };

    /**
     * Convert a figma section layer to a container layer.
     * @param figmaLayer The figma layer to convert
     * @param newLayer The converted layer
     * @param partialLayer The partial layer properties to add to the converted layer
     * @returns The converted image layer
     */
    private static convertSectionLayer = (
        figmaLayer: FigmaSectionLayer,
        newLayer: Layer,
        partialLayer: Partial<AllProperties>,
        parentSize: ConvertFigmaLayersOptions['parentSize']
    ): Promise<ContainerProperties> => {
        const width = FigmaHelpers.convertSize(figmaLayer.width, parentSize?.width);
        const height = FigmaHelpers.convertSize(figmaLayer.height, parentSize?.height);
        const visibility = figmaLayer.visible;

        const sectionSpecificProperties = LayerPropertiesHelpers.getDefaultProperties('container') as ContainerProperties;
        newLayer.type = 'container';
        newLayer.key = generateKey('container');
        const fill = figmaLayer.fills?.[0];
        const fillColor = fill ? FigmaHelpers.convertColor(fill.color, fill.opacity) : sectionSpecificProperties.background;

        sectionSpecificProperties.width = width;
        sectionSpecificProperties.height = height;
        sectionSpecificProperties.display = visibility;
        sectionSpecificProperties.background = fillColor;
        sectionSpecificProperties.borderRadius = {
            bottomLeft: { unit: UnitOptions.Pixels, value: 10 },
            bottomRight: { unit: UnitOptions.Pixels, value: 10 },
            topLeft: { unit: UnitOptions.Pixels, value: 10 },
            topRight: { unit: UnitOptions.Pixels, value: 10 }
        };

        return merge({}, sectionSpecificProperties, partialLayer);
    };

    /**
     * Convert a figma frame layer to a container layer.
     * @param figmaLayer The figma layer to convert
     * @param newLayer The converted layer
     * @param partialLayer The partial layer properties to add to the converted layer
     * @returns The converted image layer
     */
    private static convertFrameLayer = async (
        figmaLayer: FigmaFrameLayer,
        newLayer: Layer,
        partialLayer: Partial<AllProperties>,
        parentSize: ConvertFigmaLayersOptions['parentSize']
    ): Promise<ContainerProperties> => {
        const width = FigmaHelpers.convertSize(figmaLayer.width, parentSize?.width);
        const height = FigmaHelpers.convertSize(figmaLayer.height, parentSize?.height);
        const visibility = figmaLayer.visible;
        const frameSpecificProperties = LayerPropertiesHelpers.getDefaultProperties('container') as ContainerProperties;

        if (figmaLayer.image) {
            const backgroundImage = (await FigmaHelpers.getBackupImage(figmaLayer)).media;
            frameSpecificProperties.backgroundImage = backgroundImage;
        }

        newLayer.type = 'container';
        newLayer.key = generateKey('container');

        const fill = figmaLayer.fills?.[0];
        const fillColor = fill ? FigmaHelpers.convertColor(fill.color, fill.opacity) : null;
        // Border
        const borderRadius = FigmaHelpers.convertBorderRadius({
            topLeftRadius: figmaLayer.topLeftRadius,
            topRightRadius: figmaLayer.topRightRadius,
            bottomRightRadius: figmaLayer.bottomRightRadius,
            bottomLeftRadius: figmaLayer.bottomLeftRadius
        });
        const border =
            figmaLayer.strokes?.[0] &&
            FigmaHelpers.convertBorder(figmaLayer.strokes[0], {
                strokeTopWeight: figmaLayer.strokeTopWeight,
                strokeRightWeight: figmaLayer.strokeRightWeight,
                strokeBottomWeight: figmaLayer.strokeBottomWeight,
                strokeLeftWeight: figmaLayer.strokeLeftWeight
            });
        // Shadow
        const shadow = figmaLayer.effects?.[0] && FigmaHelpers.convertShadow(figmaLayer.effects[0]);

        frameSpecificProperties.width = width;
        frameSpecificProperties.height = height;
        frameSpecificProperties.display = visibility;
        frameSpecificProperties.overflow = figmaLayer.clipsContent ? OverflowOptions.Hidden : OverflowOptions.Visible;
        if (fillColor) frameSpecificProperties.background = fillColor;
        frameSpecificProperties.borderRadius = borderRadius;
        frameSpecificProperties.border = border;
        frameSpecificProperties.shadow = shadow;

        return merge({}, frameSpecificProperties, partialLayer);
    };

    /**
     * Checks if a layer is flipped
     * @param node The node to check
     * @returns If a layer is flipped
     */
    private static getLayerFlip = (node: FigmaLayer | SceneNode): { flippedX: boolean; flippedY: boolean } => {
        const removeRotation = (matrix: SceneNode['relativeTransform']): SceneNode['relativeTransform'] => {
            const [[a, c, e], [b, d, f]] = matrix; // Extract components

            // Compute rotation angle
            const angle = Math.atan2(b, a);

            // Compute inverse rotation matrix
            const cos = Math.cos(-angle);
            const sin = Math.sin(-angle);
            const inverseRotation: [[number, number], [number, number]] = [
                [cos, sin],
                [-sin, cos]
            ];

            // Apply inverse rotation to remove rotation
            const newA = inverseRotation[0][0] * a + inverseRotation[0][1] * b;
            const newC = inverseRotation[0][0] * c + inverseRotation[0][1] * d;
            const newB = inverseRotation[1][0] * a + inverseRotation[1][1] * b;
            const newD = inverseRotation[1][0] * c + inverseRotation[1][1] * d;

            // Return as SceneNodeRelativeTransform (keeping translation [e, f] intact)
            return [
                [newA, newC, e],
                [newB, newD, f]
            ];
        };

        if (!('relativeTransform' in node)) return { flippedX: false, flippedY: false }; // Only applicable to transformable nodes

        const [[a, c], [b, d]] = removeRotation(node.relativeTransform);

        // Determinant of the 2x2 matrix
        const determinant = a * d - b * c;

        if (determinant < 0) {
            const [[scaleX, ,], [, scaleY]] = node.relativeTransform;
            return { flippedX: scaleX < 0, flippedY: scaleY < 0 };
        } else {
            return { flippedX: false, flippedY: false };
        }
    };

    /**
     * Check if the value is a Figma mixed value.
     * @param value - Any value from a Figma layer.
     */
    private static isFigmaMixed = (value: unknown): value is symbol => {
        return typeof value === 'symbol' && value.toString().includes('mixed');
    };

    /**
     * Gets the absolute position of a figma node
     * @param node - The node to get the absolute position from
     * @returns The x and y position of the node
     */
    private static getAbsolutePosition = (node: FigmaLayer) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, __, x, ___, ____, y] = node.absoluteTransform.flat();
        return { x, y };
    };

    /**
     * Gets the relative position of a figma node
     * @param node - The node to get the relative position from
     * @returns The x and y position of the node
     */
    private static getRelativePosition = (node: FigmaLayer) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, __, x, ___, ____, y] = node.relativeTransform.flat();
        return { x, y };
    };

    /**
     * Convert Figma size to TD width or height.
     * @param size - Figma size.
     * @returns TD width or height.
     */
    private static convertSize = (
        size: FigmaRectangleLayer['width'] | FigmaRectangleLayer['height'] | null,
        parentSize: number | undefined
    ): AllProperties['width'] | AllProperties['height'] => {
        if (!size) {
            return {
                value: '',
                unit: UnitOptions.Pixels,
                resize: ResizeOptions['Fixed']
            };
        }

        if (parentSize) {
            const percentage = NumberHelpers.calculatePercentage(parentSize, size);

            return {
                value: percentage,
                unit: UnitOptions.Percentages,
                resize: ResizeOptions['Fixed']
            };
        }

        return {
            value: Math.round(size) ?? 1,
            unit: UnitOptions.Pixels,
            resize: ResizeOptions['Fixed']
        };
    };

    /**
     * Calculate the bounds of a layer with shadow.
     * @param layer - Figma layer.
     * @returns The width, height, top extension and left extension of the layer.
     */
    private static calculateBoundsWithShadow(layer: FigmaRectangleLayer): { width: number; height: number; topExtension: number; leftExtension: number } {
        // If no effects or empty effects array, return original dimensions
        if (!layer.effects || layer.effects.length === 0) {
            return {
                width: layer.width,
                height: layer.height,
                topExtension: 0,
                leftExtension: 0
            };
        }

        let leftExtension = 0;
        let rightExtension = 0;
        let topExtension = 0;
        let bottomExtension = 0;

        // Only process DROP_SHADOW effects as INNER_SHADOW doesn't affect bounds
        const dropShadows = layer.effects.filter((effect) => effect.type === 'DROP_SHADOW');

        for (const shadow of dropShadows) {
            // Calculate shadow extension in each direction
            const blurSpread = shadow.radius + (shadow.spread || 0);

            // Left extension
            leftExtension = Math.max(leftExtension, blurSpread - Math.max(shadow.offset.x, 0));

            // Right extension
            rightExtension = Math.max(rightExtension, blurSpread + Math.max(shadow.offset.x, 0));

            // Top extension
            topExtension = Math.max(topExtension, blurSpread - Math.max(shadow.offset.y, 0));

            // Bottom extension
            bottomExtension = Math.max(bottomExtension, blurSpread + Math.max(shadow.offset.y, 0));
        }

        return {
            width: layer.width + leftExtension + rightExtension,
            height: layer.height + topExtension + bottomExtension,
            topExtension,
            leftExtension
        };
    }

    /**
     * Convert Figma stroke to TD border.
     * @param stroke - Figma stroke.
     * @param strokeWeight - Figma stroke weight (it is separate from the strokes)
     * @returns TD border.
     */
    private static convertBorder = (stroke: Paint, strokeWeight: FigmaStrokeWeight): Border => {
        const defaultBorder: Border = cloneDeep(defaultProperties.border);
        if (!stroke) return defaultBorder;
        if (!stroke.visible) return defaultBorder;
        if (stroke.type !== 'SOLID') return defaultBorder;

        const color = FigmaHelpers.convertColor(stroke.color, stroke.opacity ?? 1);

        return {
            borderColor: ColorHelpers.convertColorToSimpleColor(color),
            borderStyle: BorderStyleOptions.Solid,
            borderWidth: {
                top: {
                    value: Math.round(strokeWeight.strokeTopWeight),
                    unit: SizeUnitOptions.Px
                },
                right: {
                    value: Math.round(strokeWeight.strokeRightWeight),
                    unit: SizeUnitOptions.Px
                },
                bottom: {
                    value: Math.round(strokeWeight.strokeBottomWeight),
                    unit: SizeUnitOptions.Px
                },
                left: {
                    value: Math.round(strokeWeight.strokeLeftWeight),
                    unit: SizeUnitOptions.Px
                }
            }
        };
    };

    /**
     * Convert Figma corner radius to TD border radius.
     * @param cornerRadius - Figma corner radius.
     * @returns TD border radius.
     */
    private static convertBorderRadius = (borderRadius: FigmaBorderRadius): AllProperties['borderRadius'] => {
        if (!borderRadius) {
            const defaultRadius: AllProperties['borderRadius']['topLeft'] = {
                value: 0,
                unit: BorderRadiusUnitOptions['Px']
            };

            return {
                topLeft: defaultRadius,
                topRight: defaultRadius,
                bottomRight: defaultRadius,
                bottomLeft: defaultRadius
            };
        }

        return {
            topLeft: {
                value: Math.round(borderRadius.topLeftRadius),
                unit: BorderRadiusUnitOptions['Px']
            },
            topRight: {
                value: Math.round(borderRadius.topRightRadius),
                unit: BorderRadiusUnitOptions['Px']
            },
            bottomRight: {
                value: Math.round(borderRadius.bottomRightRadius),
                unit: BorderRadiusUnitOptions['Px']
            },
            bottomLeft: {
                value: Math.round(borderRadius.bottomLeftRadius),
                unit: BorderRadiusUnitOptions['Px']
            }
        };
    };

    /**
     * Convert Figma color to TD color.
     * @param rgb - Figma rgb color which is between 0 and 1.
     * @param opacity - Figma opacity.
     * @returns TD color.
     */
    private static convertColor = (rgb: Rgba, opacity?: number): Color => {
        if (!rgb) rgb = { r: 255, g: 255, b: 255, a: 0 };

        rgb.r = Math.round(rgb.r * 255);
        rgb.g = Math.round(rgb.g * 255);
        rgb.b = Math.round(rgb.b * 255);
        rgb.a = Math.round((rgb.a ?? opacity ?? 1) * 100) / 100;

        const newColor: Color = cloneDeep(defaultColor);

        const hex = ColorHelpers.convertRgbToHex([rgb.r, rgb.g, rgb.b]);
        if (!hex) {
            return newColor;
        }

        newColor.points[0].color.rgb = rgb;
        newColor.points[0].color.hex = hex;
        return newColor;
    };

    /**
     * Get rotation from transform.
     * @param transform - Figma transform.
     * @returns Rotation.
     */
    static getRotationFromTransform = (transform: Transform): AllProperties['rotation'] => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [[a, c, e], [b, d, f]] = transform;

        // Calculate rotation angle in radians.
        const rotationRadians = Math.atan2(b, a);

        // Convert to degrees.
        const rotationDegrees = rotationRadians * (180 / Math.PI);

        return roundToNearestDecimal(rotationDegrees);
    };

    /**
     * Get skew from transform.
     * @param transform - Figma transform.
     * @returns Skew.
     */
    private static getSkewFromTransform = (transform: Transform): AllProperties['skew'] => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [[a, c, e], [b, d, f]] = transform;

        // Calculate skew angles in radians.
        const skewX = Math.atan(c); // Skew along the X axis.
        const skewY = Math.atan(b); // Skew along the Y axis.

        // Convert to degrees if needed.
        const skewXDegrees = skewX * (180 / Math.PI);
        const skewYDegrees = skewY * (180 / Math.PI);

        return {
            xOffset: roundToNearestDecimal(skewXDegrees),
            yOffset: roundToNearestDecimal(skewYDegrees)
        };
    };

    /**
     * Convert Figma blend mode to TD blend mode.
     * @param blendMode - Figma blend mode.
     * @returns TD blend mode.
     */
    private static convertBlendMode = (blendMode: BlendMode): AllProperties['blendMode'] => {
        const blendModeMap: Record<BlendMode, BlendModeOptions> = {
            NORMAL: BlendModeOptions['Normal'],
            MULTIPLY: BlendModeOptions['Multiply'],
            SCREEN: BlendModeOptions['Screen'],
            OVERLAY: BlendModeOptions['Overlay'],
            DARKEN: BlendModeOptions['Darken'],
            LIGHTEN: BlendModeOptions['Lighten'],
            COLOR_DODGE: BlendModeOptions['ColorDodge'],
            COLOR_BURN: BlendModeOptions['ColorBurn'],
            HARD_LIGHT: BlendModeOptions['HardLight'],
            SOFT_LIGHT: BlendModeOptions['SoftLight'],
            DIFFERENCE: BlendModeOptions['Difference'],
            EXCLUSION: BlendModeOptions['Exclusion'],
            HUE: BlendModeOptions['Hue'],
            SATURATION: BlendModeOptions['Saturation'],
            COLOR: BlendModeOptions['Color'],
            LUMINOSITY: BlendModeOptions['Luminosity'],
            PASS_THROUGH: BlendModeOptions['Normal'],
            LINEAR_BURN: BlendModeOptions['Multiply'],
            LINEAR_DODGE: BlendModeOptions['Screen']
        };

        return blendModeMap[blendMode] || null;
    };

    /**
     * Convert a image url to a Src object.
     * Get the file size and dimensions of the image.
     * @param src - Image url.
     * @param name - Image name.
     * @param extension - Image extension.
     * @returns Src object.
     */
    private static convertToMediaSrc = async (src: string, name: string, extension = 'png'): Promise<Src> => {
        const headResponse = await fetch(src, { method: 'HEAD' });
        const imageSize = headResponse.headers.get('Content-Length');
        const size = imageSize ? parseInt(imageSize, 10) : 0;

        const dimensions: NonNullable<Src['assetData']> = await new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve({ width: img.width, height: img.height });
            img.onerror = () => reject(new Error('Failed to load image.'));
            img.src = src;
        });

        const humanSize = size < 1024 ? `${size} B` : `${(size / 1024).toFixed(2)} KB`;

        return {
            url: src,
            assetGalleryInput: true,
            extension,
            fileName: name,
            fileType: 'image/png',
            humanSize,
            size,
            title: name,
            assetData: {
                width: dimensions.width,
                height: dimensions.height
            },
            openAssetEditor: true
        };
    };

    /**
     * Convert Figma shadow to TD shadow.
     * @param effect - Figma effect shadow.
     * @returns TD shadow.
     */
    private static convertShadow = (effect: Effect): Shadow => {
        const defaultShadow: Shadow = cloneDeep(defaultProperties.shadow);
        if (!effect) return defaultShadow;
        if (!effect.visible) return defaultShadow;
        if (effect.type === 'BACKGROUND_BLUR' || effect.type === 'LAYER_BLUR') return defaultShadow;

        if (effect.type === 'DROP_SHADOW' || effect.type === 'INNER_SHADOW') {
            const shadowStyle = (() => {
                switch (effect.type) {
                    case 'DROP_SHADOW':
                    default:
                        return ShadowStyleOptions.Initial;
                    case 'INNER_SHADOW':
                        return ShadowStyleOptions.Inset;
                }
            })();

            const color = FigmaHelpers.convertColor(effect.color);

            return {
                shadowStyle,
                blur: Math.round(effect.radius),
                color: ColorHelpers.convertColorToSimpleColor(color),
                spread: Math.round(effect.spread ?? 0),
                xOffset: Math.round(effect.offset.x),
                yOffset: Math.round(effect.offset.y)
            };
        }

        return defaultShadow;
    };

    /**
     * Convert Figma text to TD text.
     * @param text The text to convert
     * @param textCase The Figma text case to convert to
     * @returns The converted text
     */
    private static convertTextTransform = (textCase: TextNode['textCase']): TextStyling['textTransform'] => {
        switch (textCase) {
            case 'ORIGINAL':
                return TextTransformOptions.None;
            case 'UPPER':
                return TextTransformOptions.Uppercase;
            case 'LOWER':
                return TextTransformOptions.Lowercase;
            case 'TITLE':
                return TextTransformOptions.Capitalize;
            default:
                return TextTransformOptions.None;
        }
    };

    /**
     * Convert Figma text height to TD height.
     * @param textAutoResize - Figma text auto resize
     * @param height - Figma height
     * @returns TD height
     */
    private static convertHeight = (
        textAutoResize: TextNode['textAutoResize'],
        height: TextNode['height'],
        convertToImage: boolean,
        parentHeight: number | undefined
    ): Properties['height'] => {
        if ((textAutoResize === 'WIDTH_AND_HEIGHT' || textAutoResize === 'HEIGHT') && !convertToImage) {
            return {
                value: '',
                unit: SizeUnitOptions.Px,
                resize: ResizeOptions.Hug
            };
        } else if (parentHeight) {
            const percentage = NumberHelpers.calculatePercentage(parentHeight, height);

            return {
                value: percentage,
                unit: UnitOptions.Percentages,
                resize: ResizeOptions['Fixed']
            };
        } else {
            return {
                value: Math.round(height),
                unit: SizeUnitOptions.Px,
                resize: ResizeOptions.Fixed
            };
        }
    };

    /**
     * Convert Figma text width to TD height.
     * @param textAutoResize - Figma text auto resize
     * @param height - Figma width
     * @returns TD width
     */
    private static convertWidth = (
        textAutoResize: TextNode['textAutoResize'],
        width: TextNode['width'],
        convertToImage: boolean,
        parentWidth: number | undefined
    ): Properties['width'] => {
        if (textAutoResize === 'WIDTH_AND_HEIGHT' && !convertToImage) {
            return {
                value: '',
                unit: SizeUnitOptions.Px,
                resize: ResizeOptions.Hug
            };
        } else if (parentWidth) {
            const percentage = NumberHelpers.calculatePercentage(parentWidth, width);

            return {
                value: percentage,
                unit: UnitOptions.Percentages,
                resize: ResizeOptions['Fixed']
            };
        } else {
            return {
                value: Math.round(width),
                unit: SizeUnitOptions.Px,
                resize: ResizeOptions.Fixed
            };
        }
    };

    /**
     * Convert a Figma font name to a TD font name.
     * @param fontName - The font name to convert
     * @param name - The name of the text node
     * @returns The font name
     */
    private static convertFontName = (fontName: TextNode['fontName'], name: TextNode['name']): string => {
        if (!FigmaHelpers.isFigmaMixed(fontName)) {
            return fontName?.family;
        }
        return name;
    };

    /**
     * Convert a Figma font name to a TD font source.
     * @param fontName The font name to convert
     * @returns The font source
     */
    private static convertFontSource = (fontName: string): TextStyling['fontSource'] => {
        const fontSource = (() => {
            if (!fontName) return false;

            const isStandardFont = FontHelpers.isStandardFont(fontName);
            if (isStandardFont) return 'standard';
            const isGoogleFont = FontHelpers.isGoogleFont(fontName);
            if (isGoogleFont) return 'googleFonts';

            //Font is missing
            return false;
        })();

        if (fontSource === 'googleFonts') {
            const font = GoogleFonts.get().find((item) => item.family === fontName);

            if (font) {
                WebFont.load({
                    google: {
                        families: [`${font.family}:${font.variants.join(',')}`]
                    }
                });
            }
        }

        return fontSource ? fontSource : 'brandGuide';
    };

    /**
     * Convert Figma font weight to TD font variant.
     * @param fontWeight The font weight to convert
     * @param fontSource The font source of the text
     * @returns The font variant
     */
    private static convertFontVariant = (fontWeight: TextNode['fontWeight'], fontSource: TextStyling['fontSource']): TextStyling['fontVariant'] => {
        if (fontSource === 'googleFonts' && !FigmaHelpers.isFigmaMixed(fontWeight)) {
            const convertedGoogleFontWeight = FontHelpers.weightMapping[fontWeight];
            if (convertedGoogleFontWeight === 'regular') return 'regular';
            return String(fontWeight);
        }
        if (typeof fontWeight !== 'number') return 'regular';
        const convertedFontWeight = FontHelpers.weightMapping[fontWeight];
        if (fontSource === 'standard' && convertedFontWeight) return convertedFontWeight;

        return 'regular';
    };

    /**
     * Convert Figma lineheight to TD lineheight.
     * @param lineHeight Figma lineheight
     * @param fontSize Font size of the text
     * @returns The converted lineheight
     */
    private static convertLineHeight = (lineHeight: TextNode['lineHeight'], fontSize: TextNode['fontSize']): TextStyling['lineHeight'] | undefined => {
        if (FigmaHelpers.isFigmaMixed(lineHeight) || FigmaHelpers.isFigmaMixed(fontSize)) return undefined;
        if (lineHeight.unit === 'PERCENT') return lineHeight.value / 100;
        if (lineHeight.unit === 'PIXELS') return lineHeight.value / fontSize;

        return undefined;
    };

    /**
     * Convert Figma letter spacing to TD letter spacing.
     * @param letterSpacing Figma letter spacing
     * @param fontSize Font size of the text
     * @returns The converted letter spacing
     */
    private static convertLetterSpacing = (letterSpacing: TextNode['letterSpacing'], fontSize: TextNode['fontSize']): TextStyling['letterSpacing'] => {
        if (FigmaHelpers.isFigmaMixed(letterSpacing) || !('unit' in letterSpacing) || !('value' in letterSpacing)) return 0;
        if (letterSpacing.unit === 'PIXELS') return letterSpacing.value;
        if (FigmaHelpers.isFigmaMixed(fontSize)) return 0;
        if (letterSpacing.unit === 'PERCENT') return fontSize * (letterSpacing.value / 100);

        return 0;
    };

    /**
     * Convert Figma shadow to TD text shadow.
     * @param effect - Figma effect shadow.
     * @returns TD text shadow.
     */
    private static convertTextShadow = (effect: Effect): TextShadow => {
        const defaultShadow: TextShadow = cloneDeep(defaultProperties.shadow);
        if (!effect) return defaultShadow;
        if (!effect.visible) return defaultShadow;
        if (effect.type === 'BACKGROUND_BLUR' || effect.type === 'LAYER_BLUR' || effect.type === 'INNER_SHADOW') return defaultShadow;

        if (effect.type === 'DROP_SHADOW') {
            const color = FigmaHelpers.convertColor(effect.color);

            return {
                shadowStyle: TextShadowStyleOptions.Initial,
                blur: effect.radius,
                color: ColorHelpers.convertColorToSimpleColor(color),
                xOffset: effect.offset.x,
                yOffset: effect.offset.y
            };
        }

        return defaultShadow;
    };

    /**
     * Convert Figma horizontal alignment to TD horizontal alignment.
     * @param textAlignHorizontal - Figma horizontal alignment of the text
     * @returns TD horizontal alignment of the text
     */
    private static convertTextHorizontalAlignment = (textAlignHorizontal: TextNode['textAlignHorizontal']): TextStyling['textAlignHorizontal'] => {
        switch (textAlignHorizontal) {
            case 'CENTER':
                return TextAlignHorizontalOptions.Center;
            case 'RIGHT':
                return TextAlignHorizontalOptions.Right;
            case 'JUSTIFIED':
                return TextAlignHorizontalOptions.Justify;
            case 'LEFT':
            default:
                return TextAlignHorizontalOptions.Left;
        }
    };

    /**
     * Convert Figma vertical alignment to TD horizontal alignment.
     * @param textAlignVertical - Figma vertical alignment of the text
     * @returns TD vertical alignment of the text
     */
    private static convertTextVerticalAlignment = (textAlignVertical: TextNode['textAlignVertical']): TextStyling['textAlignVertical'] => {
        switch (textAlignVertical) {
            case 'CENTER':
                return TextAlignVerticalOptions.Center;
            case 'BOTTOM':
                return TextAlignVerticalOptions.FlexEnd;
            default:
            case 'TOP':
                return TextAlignVerticalOptions.FlexStart;
        }
    };

    /**
     * Convert Figma text decoration to TD text decoration.
     * @param textDecoration - Figma text decoration
     * @returns TD text decoration
     */
    private static convertTextDecoration = (textDecoration: TextNode['textDecoration']): TextStyling['textDecoration'] => {
        switch (textDecoration) {
            case 'STRIKETHROUGH':
                return TextDecorationOptions.LineThrough;
            case 'UNDERLINE':
                return TextDecorationOptions.Underline;
            default:
                return TextDecorationOptions.None;
        }
    };

    /**
     * Convert Figma text decoration style to TD text decoration style.
     * @param textDecorationStyle - Figma text decoration style
     * @returns TD text decoration style
     */
    private static convertTextDecorationStyle = (textDecorationStyle: FigmaTextLayer['textDecorationStyle']): TextStyling['textDecorationStyle'] => {
        switch (textDecorationStyle) {
            case 'SOLID':
                return TextDecorationStyleOptions.Solid;
            case 'WAVY':
                return TextDecorationStyleOptions.Wavy;
            case 'DOTTED':
                return TextDecorationStyleOptions.Dotted;
            default:
                return TextDecorationStyleOptions.Solid;
        }
    };

    /**
     * Generate a backup image layer from a figma layer.
     * @param figmaLayer The figma layer to generate a backup image from
     * @returns The backup image layer properties
     */
    private static getBackupImage = async (figmaLayer: FigmaLayer): Promise<ImageProperties> => {
        const backupImageProperties = LayerPropertiesHelpers.getDefaultProperties('image') as ImageProperties;
        if (!figmaLayer.image) return backupImageProperties;

        const backupImage: Media = {
            src: false,
            size: MediaSizeOptions.Contain,
            position: MediaPositionOptions.CenterCenter,
            repeat: MediaRepeatOptions.NoRepeat
        };

        TemplateDesignerStore.save(['state.isUploading', true, false]);
        const fileExtension = figmaLayer.image.split('.').pop();

        const safeName = figmaLayer.name.replace(/[^a-zA-Z0-9]/g, '');
        const response = await axios.post(
            process.env.APP_MEDIA_URL + 'media/publicUploadedFigmaImage',
            {
                filename: (safeName || 'image') + `.${fileExtension}`,
                path: figmaLayer.image
            },
            {
                headers: { Authorization: `Bearer ${User.get('mediaServicesApiToken')}` }
            }
        );
        const url = response.data.url;

        if (url) {
            backupImage.src = await FigmaHelpers.convertToMediaSrc(url, figmaLayer.name, fileExtension);
        }

        if (backupImage) {
            backupImageProperties.media = backupImage;
        }

        return backupImageProperties;
    };
}

export { FigmaHelpers };
